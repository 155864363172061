import React, { FunctionComponent, useEffect, useState } from "react";

import { Project } from "src/lib/graphql/types";
import { toJpeg } from "src/lib/imageCompressor";

type ProjectAvatarProps = {
  project: Pick<Project, "avatar" | "thumbnail" | "id">;
  size?: string;
};

const ProjectAvatar: FunctionComponent<ProjectAvatarProps> = ({
  project,
  size = "100%",
}) => {
  const [webmSupport, setWebmSupport] = useState(true);

  const src = project.avatar;

  useEffect(() => {
    const video = document.createElement("video");

    if (video.canPlayType(`video/webm; codecs="vp8, vorbis"`) !== "probably") {
      setWebmSupport(false);
    }
  }, []);

  if (!src) {
    return null;
  }

  return (
    <div className="project-avatar">
      {src.endsWith("webm") ? (
        webmSupport ? (
          <video src={src} autoPlay playsInline muted loop />
        ) : (
          project.thumbnail && <img src={project.thumbnail} />
        )
      ) : src.endsWith("mp4") ? (
        <video src={src} autoPlay playsInline muted loop />
      ) : (
        <img src={toJpeg(src)} />
      )}
      <style jsx>{`
        .project-avatar {
          height: ${size};
          width: ${size};
          background: #ededed;
        }

        .project-avatar img,
        .project-avatar video {
          height: ${size};
          width: ${size};
        }

        .project-avatar img,
        .project-avatar video {
          object-fit: cover;
        }
      `}</style>
    </div>
  );
};

export default ProjectAvatar;
