import React, { FC } from "react";
import Fade from "@mui/material/Fade";

import { Org, Project, User } from "src/lib/graphql/types";
import ProjectAvatar from "./ProjectAvatar";
import { simLink } from "src/lib/environment";
import { featureColors } from "./FeaturedProjectsCarousel";

type FeaturedProjectProps = {
  project?: Pick<Project, "id" | "pathWithNamespace" | "name" | "image"> & {
    owner?: Pick<Org, "avatar" | "image"> | Pick<User, "image">;
  };
  index: number;
};

const FeaturedProject: FC<FeaturedProjectProps> = ({ project, index }) => (
  <a
    // href changed for core.hash.ai deprecation
    // href={project ? simLink(project, "stable") : undefined}
    href={project ? `/${project.pathWithNamespace}` : undefined}
    target="_blank"
    className="featured-project normal-text"
  >
    <div className="shadow" />
    {project && (
      <Fade in timeout={300} style={{ transitionDelay: `${index * 100}ms` }}>
        <div className="content">
          <div className="description">
            <div
              className={[
                "featured-project-title",
                featureColors[index % 4],
              ].join(" ")}
            >
              {project.name}
            </div>
            Open this simulation <span>{">"}</span>
          </div>
          <ProjectAvatar project={project} />
        </div>
      </Fade>
    )}
  </a>
);

export default FeaturedProject;
