import React, { FunctionComponent, CSSProperties } from "react";
import Link from "next/link";

import { ContentTypeDisplay } from "src/components/index/BrowseAndExploreBanner";
import { useUser } from "src/hooks/useUser";
import { trackEvent } from "src/lib/analytics";
type TypeTileProps = {
  type: ContentTypeDisplay;
  style?: CSSProperties;
};

const TypeTile: FunctionComponent<TypeTileProps> = ({ type }) => {
  const [user] = useUser();
  const onClick = () =>
    trackEvent(
      {
        category: "Activity",
        action: `Clicked ${type.type} tile from homepage`,
      },
      user
    );
  return (
    <div className={`homepage-type-card ${type.type}`}>
      <Link href={type.href} key={type.type}>
        <a onClick={onClick}>
          <div className="type-card-icon">{type.icon}</div>
          <div className="type-card-label">{type.label}</div>
        </a>
      </Link>
    </div>
  );
};

export default TypeTile;
