import Link from "next/link";
import React, { FunctionComponent } from "react";
import { useUser } from "src/hooks/useUser";
import { trackEvent } from "src/lib/analytics";
import ArrowRightIcon from "../icons/Arrows/ArrowRightIcon";

type GetStartedBannerProps = {
  link: string;
  logClick?: () => void;
  logDocsClick?: () => void;
};

const GetStartedBanner: FunctionComponent<GetStartedBannerProps> = ({
  link,
  logClick,
  logDocsClick,
}) => {
  const [user] = useUser();
  const onClick = () =>
    trackEvent(
      {
        category: "Activity",
        action: "Clicked 'Popular Simulations'",
      },
      user
    );
  return (
    <div className="get-started-banner">
      <div className="container both-banners">
        <div className="content-left">
          <div className="container get-started">
            <h2 className="get-started-message heading-4 white">
              NEW TO SIMULATION?
            </h2>
            <div className="buttons">
              <a href={link} onClick={logClick}>
                <button className="open-getting-started normal-text bold">
                  <span className="desktop-only light">Get&nbsp;</span>
                  HASH Core&nbsp;
                  <span className="light">on GitHub</span>
                  <ArrowRightIcon />
                </button>
              </a>
              <Link href="/docs/simulation/tutorials/hello-hash">
                <a onClick={logDocsClick}>
                  <button className="read-docs normal-text bold">
                    Read our Docs
                    <img src={"/assets/library.svg"} />
                  </button>
                </a>
              </Link>
            </div>
          </div>
        </div>

        <div className="content-right">
          <div className="container popular-sims">
            <Link href="/models?sort=popularity">
              <a onClick={onClick}>
                <div className="popular-simulations">
                  <h2 className="heading-4">
                    <span className="popular">POPULAR</span>
                    <br />
                    <span className="simulations">SIMULATIONS</span>
                  </h2>
                  <p>
                    View models trending now <span>&gt;</span>
                  </p>
                </div>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStartedBanner;
