import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import useMediaQuery from "@mui/material/useMediaQuery";
import Link from "next/link";

import { featuredProjectsQuery } from "src/lib/graphql/project.queries";
import { FeaturedProjectsQuery } from "src/lib/graphql/types";
import FeaturedProject from "src/components/index/FeaturedProject";

const CAROUSEL_TRANSITION_DELAY = 5000;
const CAROUSEL_TRANSITION_DURATION = 500;

export const featureColors = ["turquoise", "bright-blue", "purple", "pink"];

const FeaturedProjectsCarousel: React.FC = () => {
  const { data } = useQuery<FeaturedProjectsQuery>(featuredProjectsQuery, {
    errorPolicy: "all",
  });

  const [carousel, setCarousel] = useState<boolean>(false);
  const [transitioning, setTransitioning] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const featuredProjects = data?.specialProjects || [];

  const lg = useMediaQuery("(max-width: 1200px)");
  const sm = useMediaQuery("(max-width: 767px)");
  const xxs = useMediaQuery("(max-width: 350px)");

  const slideForward = () => {
    setTransitioning(true);
    setTimeout(() => {
      setCurrentIndex(
        currentIndex - 1 < 0 ? featuredProjects.length - 1 : currentIndex - 1
      );
      setTransitioning(false);
    }, CAROUSEL_TRANSITION_DURATION);
  };

  const numberOfVisibleFeatures = lg ? (sm ? (xxs ? 1 : 2) : 3) : 4;

  useEffect(() => {
    setCarousel(
      numberOfVisibleFeatures < featuredProjects.length &&
        featuredProjects.length > 0
    );
  }, [numberOfVisibleFeatures, featuredProjects.length]);

  useEffect(() => {
    if (!transitioning && carousel)
      setTimeout(slideForward, CAROUSEL_TRANSITION_DELAY);
  }, [transitioning, carousel]);

  const arrayLength =
    featuredProjects.length === 0
      ? 4
      : numberOfVisibleFeatures + (carousel ? 1 : 0);

  if (sm) {
    return (
      <div className="featured-projects-mobile container">
        <h4>Example models</h4>
        {featuredProjects.map((project, index) => (
          <div key={project.id}>
            <Link href={`/${project.pathWithNamespace}`}>
              <a
                className={[
                  "featured-project-title",
                  featureColors[index % 4],
                ].join(" ")}
              >
                <span>{project.name}</span>
                <span>{" >"}</span>
              </a>
            </Link>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div
      className={["featured-projects-wrapper", carousel ? "carousel" : ""].join(
        " "
      )}
    >
      <div
        className="featured-projects"
        style={transitioning ? { left: 0, transition: "left 500ms" } : {}}
      >
        {new Array(arrayLength).fill("").map((_, position) => {
          const index =
            featuredProjects.length <= position
              ? -1
              : currentIndex + position - 1 < 0
              ? featuredProjects.length - 1
              : currentIndex + position - 1 > featuredProjects.length - 1
              ? currentIndex + position - 1 - featuredProjects.length
              : currentIndex + position - 1;

          const project = featuredProjects
            ? featuredProjects[index]
            : undefined;

          return (
            <FeaturedProject
              key={project ? project.id : position}
              index={index}
              project={project}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FeaturedProjectsCarousel;
