import gql from "graphql-tag";
import { projectFieldsFragment } from "./fragments";

export const projectQuery = gql`
  query getProject(
    $projectPath: String
    $oldId: ID
    $oldType: OldProjectType
  ) {
    project(projectPath: $projectPath, oldId: $oldId, oldType: $oldType) {
      ...ProjectFields
    }
  }
  ${projectFieldsFragment}
`;

export const featuredProjectsQuery = gql`
  query featuredProjects {
    specialProjects(type: Featured) {
      id
      name
      avatar
      thumbnail
      pathWithNamespace
      owner {
        ... on User {
          id
          image
        }
        ... on Org {
          id
          avatar
          image
        }
      }
    }
  }
`;

export const searchProjectsQuery = gql`
  query searchProjects(
    $query: String
    $subject: [String!]
    $perPage: Int
    $page: Int
    $types: [SearchableTypeName!]
    $sort: SortOption
    $language: BehaviorLanguage
    $temporalCoverage: String
    $dataFrequency: String
    $spatialCoverage: String
    $releasedOnly: Boolean
  ) {
    searchProjects(
      query: $query
      subjects: $subject
      perPage: $perPage
      page: $page
      types: $types
      sort: $sort
      language: $language
      temporalCoverage: $temporalCoverage
      dataFrequency: $dataFrequency
      spatialCoverage: $spatialCoverage
      releasedOnly: $releasedOnly
    ) {
      page
      perPage
      totalCount
      sort
      results {
        ... on Subject {
          __typename
          id
          name
          namespace
          pathWithNamespace
          visibility
          description
          owner {
            __typename
            ... on User {
              id
              shortname
              fullName
              image
            }
            ... on Org {
              id
              shortname
              name
              avatar
            }
          }
          ownerType
          relevance
          createdAt
          updatedAt
        }
        ... on Project {
          __typename
          id
          name
          namespace
          path
          pathWithNamespace
          visibility
          type
          description
          avatar
          thumbnail
          keywords
          owner {
            __typename
            ... on User {
              id
              shortname
              fullName
              image
            }
            ... on Org {
              id
              shortname
              name
              avatar
            }
          }
          ownerType
          dependents
          forkCount
          starCount
          popularity
          relevance
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const updateProjectMutation = gql`
  mutation updateProject(
    $projectPath: String!
    $visibility: VisibilityLevel
    $name: String
    $namespace: String
    $description: String
    $avatar: Upload
    $image: Upload
    $keywords: [String!]
    $subject: [SchemaReferenceInput!]
    $license: String
    $newLicense: LicenseCreationInput
    $temporalCoverage: String
    $dataFrequency: String
    $spatialCoverage: String
  ) {
    updateProject(
      projectPath: $projectPath
      data: {
        name: $name
        visibility: $visibility
        description: $description
        avatar: $avatar
        image: $image
        namespace: $namespace
        keywords: $keywords
        license: $license
        newLicense: $newLicense
        subject: $subject
        temporalCoverage: $temporalCoverage
        dataFrequency: $dataFrequency
        spatialCoverage: $spatialCoverage
      }
    ) {
      ...ProjectFields
      canUserEdit
    }
  }
  ${projectFieldsFragment}
`;

export const deleteProjectMutation = gql`
  mutation deleteProject($projectPath: String!) {
    deleteProject(projectPath: $projectPath)
  }
`;
