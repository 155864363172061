import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

import { IndexListingType } from "src/lib/globalTypes";
import { useUser } from "src/hooks/useUser";
import { trackEvent } from "src/lib/analytics";
import TypeTile from "src/components/index/TypeTile";
import SimulationIcon from "src/components/icons/SimulationIcon";
import BehaviorIcon from "src/components/icons/BehaviorIcon";
import DatasetIcon from "src/components/icons/DatasetIcon";
import SchemaIcon from "src/components/icons/SchemaIcon";
import Link from "next/link";

type TypeProps = {
  label: string;
  icon: string | JSX.Element;
  href: string;
};

export type ContentTypeDisplay = TypeProps & {
  type: IndexListingType | "Schema";
  plural: string;
};

export const contentTypeToPlural = {
  All: "All",
  Simulation: "Simulations",
  Dataset: "Data",
  Behavior: "Behaviors",
  Schema: "Schemas",
  Members: "Members",
  Project: "Projects",
};

export const contentTypes: ContentTypeDisplay[] = [
  {
    label: contentTypeToPlural.Simulation,
    type: "Simulation",
    icon: <SimulationIcon />,
    plural: "models",
    href: "/models?sort=popularity",
  },
  {
    label: contentTypeToPlural.Behavior,
    type: "Behavior",
    icon: <BehaviorIcon />,
    plural: "behaviors",
    href: "/behaviors?sort=popularity",
  },
  {
    label: contentTypeToPlural.Dataset,
    type: "Dataset",
    icon: <DatasetIcon />,
    plural: "data",
    href: "/data?sort=popularity",
  },
  {
    label: contentTypeToPlural.Schema,
    type: "Schema",
    icon: <SchemaIcon />,
    plural: "schemas",
    href: "/schemas?sort=createdAt",
  },
];

type BrowseAndExploreBannerProps = {
  gettingStartedLink?: string;
};

const BrowseAndExploreBanner: React.FC<BrowseAndExploreBannerProps> = ({
  gettingStartedLink,
}) => {
  const [user] = useUser();

  const logGettingStartedClick = () => {
    trackEvent(
      {
        category: "Activity",
        action: "Click Getting Started button",
      },
      user
    );
  };

  const md = useMediaQuery("(max-width: 992px)");

  return (
    <div className="homepage-explore container">
      <div className="row">
        <div className="col-lg-4">
          <h4>HASH is where models and simulations live</h4>
          <p>
            <span>
              HASH is an open-core platform for creating and exploring
              digital-twins of our complex real world. This allows for safe,
              low-cost learning and experimentation in realistic virtual
              environments.{" "}
            </span>
            <Link href="/glossary/simulation">
              <a className="text-link">{"Learn more >"}</a>
            </Link>
          </p>
          {md && gettingStartedLink && (
            <span className="get-started-button-wrapper">
              <a href={gettingStartedLink} onClick={logGettingStartedClick}>
                <button className="get-started-button">
                  <span>New to simulation?&nbsp;</span>
                  <span>Get started</span>
                </button>
              </a>
            </span>
          )}
        </div>
        <div className="col-lg-8">
          <div>
            <div className="browse-header bold">Browse and explore...</div>
            <div className="homepage-type-cards normal-text">
              {contentTypes.map((type) => (
                <TypeTile type={type} key={type.type} />
              ))}
            </div>
          </div>
          {!md && gettingStartedLink && (
            <span className="get-started-button-wrapper">
              <a href={gettingStartedLink} onClick={logGettingStartedClick}>
                <button className="get-started-button">
                  <span>New to simulation?&nbsp;</span>
                  <span>Get started</span>
                </button>
              </a>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default BrowseAndExploreBanner;
